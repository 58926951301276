import 'globalthis/auto';
import { Component } from '@angular/core';
import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
//import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { FirebaseAuthService } from './services/firebase-auth.service';
import firebase from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private _navController: NavController,
    private router: Router,
    private authService: FirebaseAuthService
  ) {
    this.initializeApp(router);
  }

  initializeApp(router2) {
    this.platform.ready().then(() => {
      //this.statusBar.styleDefault();
      this.statusBar.styleLightContent();
      this.splashScreen.hide();

      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          console.log("USUARIO LOGUEADO!");
          router2.navigateByUrl('/tabs/dashboard');
        } else {
          console.log("USUARIO NO LOGUEADO");
          router2.navigateByUrl('sign-in');
        }
      });
    });
  }

  onLogout(): void {
    console.log("SALIMOS");
    firebase.auth().signOut().then(()=>{
      this._navController.navigateRoot('/sign-in');
    });
  }
}
