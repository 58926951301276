<ion-app>
	<ion-menu side="start" content-id="main-content">
		<ion-header>
			<ion-toolbar color="success">
				<ion-title>Menu</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<ion-list lines="none">
				<ion-menu-toggle>
					<ion-item
						routerLink="/tabs/dashboard"
						routerDirection="forward"
					>
						<ion-icon name="swap-horizontal" slot="start"></ion-icon>
						<ion-label>Mis Pesadas</ion-label>
					</ion-item>
				</ion-menu-toggle>
				<ion-menu-toggle>
					<ion-item (click)="onLogout()" button>
						<ion-icon name="log-out" slot="start"></ion-icon>
						<ion-label>Salir</ion-label>
					</ion-item>
				</ion-menu-toggle>
			</ion-list>
		</ion-content>
	</ion-menu>
	<ion-router-outlet id="main-content"></ion-router-outlet>
</ion-app>
