import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{ path: 'ee', redirectTo: '/sign-in', pathMatch: 'full' },
	{ path: 'sign-in', loadChildren: './sign-in/sign-in.module#SignInPageModule' },
	{
		path: 'tabs',
		loadChildren: './tabs/tabs.module#TabsPageModule'
	},
  {
    path: 'lectura/:cliente_descripcion/:transportista_descripcion/:fecha/:carga_descripcion/:producto_descripcion/:precio/:entrada/:usuario_entrada/:fecha_entrada/:salida/:usuario_salida/:fecha_salida/:neto',
    loadChildren: () => import('./pages/lectura/lectura.module').then( m => m.LecturaPageModule)
  },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules,
			/*  enableTracing: true, */
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
