export const environment = {
  production: true,
  firebase:{
    apiKey: "AIzaSyDCCfdbmdCRklEF6bZPQQOALYKu5QtJC5g",
    authDomain: "balanzadx.firebaseapp.com",
    projectId: "balanzadx",
    storageBucket: "balanzadx.appspot.com",
    messagingSenderId: "785094536191",
    appId: "1:785094536191:web:7c953d16d50be3bad095ca",
    measurementId: "G-FLH69CT99S"
  }
};
